import React, { useEffect, useState } from 'react'
// import { Redirect } from 'react-router-dom'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks'
import { inject, observer } from 'mobx-react'
import {
    Table,
    List,
    Space,
    Button,
    Divider
} from 'antd'
import {
    SyncOutlined,
    FileExcelOutlined
} from '@ant-design/icons'
// import moment from 'moment'
// import { flatMap, merge as LMerge } from 'lodash-es'
// import { autorun, set, toJS } from 'mobx'

// import { nanoid } from 'nanoid'

import { price_k } from '../../../../ultils/datatableUtils'
import DefaultLayout from '../../../layouts/DefaultLayout'
import DataTableServices from '../../../../services/datatableServices'
import styled from 'styled-components'
import useAsyncQuery from '../../../../ultils/useAsyncQuery'

import { price_render, color_render, customer_render, order_compact_render } from '../DataTools/DataField'


const TopBarContainer = styled.div`
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
`
// const defaultLimit = 50

const DataReport = ({ usersStore, commonStore, datatableStore }) => {
    // let history = useHistory();
    const [showAll, setShowAll] = useState(false)
    const [rData, setRData] = useState(false)
    const [tColumn, setTColumn] = useState(false)
    const { id } = useParams()

    const [exportReportExcel] = useMutation(
        DataTableServices.EXPORT_REPORT_EXCEL(),
        {
            errorPolicy: 'all'
        }
    )
    // const location = useLocation();

    // const getRow = useAsyncQuery(DataTableServices.GET_ROW_BY_ID(id))

    const getRecordById = useAsyncQuery(DataTableServices.GET_RECORD_BY_ID())

    const findRecord = async (record_id) => {
        let r = await getRecordById({
            id: record_id
        })
        if (r?.data?.getReportDetail?.data?.status === 'OK') {
            return r.data.getReportDetail?.data
        }
        return false
        //   let results = r?.getReportDetail?.data
        //   if (!results?.items) return false
        //   var items = flatMap(results.items, myFlat)
        //   return items
    }

    function saveAsXlsxFile(fileName, bufferBase64) {
        var mediaType = "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,";
        var a = document.createElement('a');
        a.href = mediaType + bufferBase64;
        a.download = fileName;
        document.body.appendChild(a);
        a.style.display = 'none'
        a.click()
        document.body.removeChild(a)
    }

    async function exportReport() {
        let input = {
            data: {
                id: id
            }
        }
        let r = await exportReportExcel({
            variables: { input }
        })
        let result = r.data?.exportReportExcel?.data
        if (result?.status === 'OK') {
            saveAsXlsxFile(result?.fileOut, result?.buffer)
        }
    }


    function initColumn(group_data, table) {
        let columns = [
            {
                title: "STT",
                width: 30,
                type: "number",
                render: (value, row, index) => {
                    return index + 1
                }
            }
        ]
        for (const [key, value] of Object.entries(group_data.transformFields)) {
            let tf = group_data.transformFields[key]
            let col = {}
            col.dataIndex = key
            col.title = tf.label
            col.width = tf.width || 'auto'
            col.type = tf.type || 'text'
            col.sub_type = tf.sub_type
            if (tf.align) col.align = tf.align

            if (col.format === 'price-vn' || col.sub_type === "price_vn") {
                col.render = price_render
            }
            if (tf.click_detail === 'search_text') {
                col.render = (value, row, index) => {
                    return (
                        <a href={`/table/${table}?limit=20&start=0&txt=${value}&_sort=ngay_tao:-1`} target="_blank" title={value}>{value}</a>
                    )
                }
            }

            if (col.dataIndex === 'tong_mua') {
                col.sorter = (a, b) => a.tong_mua - b.tong_mua
                // col.sortDirections =['descend']
            }
            columns.push(col)
        }
        setTColumn(columns)
    }


    useEffect(() => {
        async function init() {
            let r = await findRecord(id)
            if (r) {
                initColumn(r.group_data, r.table)
                setRData(r.items)
            }
        }
        if (showAll) init()
        // return () => {
        //     setShowAll(false)
        // }
    }, [showAll])

    // const RowDetailByType = ({ rowData }) => {
    //     // console.log(`data`, rowData)
    //     if (rowData?.datatable.type === 'order') {
    //         return (
    //             <DataRowOrder orderData={rowData} />
    //         )
    //     }
    //     return 'hihi'
    // }

    const child_render = (record) => {
        let s = ""
        if (record._children && record._children.length > 0) {
            let c = record._children.map(c => c.code)
            s = '&recent=' + c.join(',')
        }
        return (
            <List
                style={{ background: "#E1EBE1" }}
                size="small"
                dataSource={record._children}
                bordered
                renderItem={item =>
                    <List.Item>
                        <div>
                            <span>
                                <strong>Mã đơn:</strong>
                                <a href={`https://we.nhayeu.com/oflow/board?status=Tất cả&txt=${item.code}${s}`} target="_blank" title={item.code}>{item.code}</a>
                            </span>
                            <span style={{ marginLeft: 30, marginRight: 30 }}>
                                <strong >Giá trị đơn:</strong> {price_k(item.tong_tien, '?', 3)}
                            </span>
                            <span style={{ marginRight: 30, color: item.status.color }}>
                                <strong >Trạng thái:</strong> {item.status.name}
                            </span>
                        </div>

                    </List.Item>}
            />
        )
        // <List
        //     bordered
        //     dataSource={record._children}
        //     renderItem={item => (
        //         <List.Item>
        //             {item.code}
        //         </List.Item>
        //     )}
        // />
    }

    return (
        <DefaultLayout>
            <>
                <TopBarContainer>
                    <Space>
                        {/* <Button
                            // size={'small'}
                            icon={<FileExcelOutlined />}
                            onClick={async () => {
                                await exportReport()
                            }}>
                            Xuất Excel
                        </Button> */}

                        <Button
                            // size={'small'}
                            icon={<FileExcelOutlined />}
                            onClick={() => {
                                setShowAll(true)
                            }}>
                            Xem báo cáo
                        </Button>
                    </Space>


                </TopBarContainer>
                {tColumn && rData && showAll &&
                    <Table
                        // loading={loading}
                        // className={'mainTable'}
                        rowKey="sid"
                        columns={tColumn}
                        // dataSource={rows}
                        dataSource={rData}
                        scroll={{ x: 800 }}
                        pagination={false}

                        expandable={{
                            expandedRowRender: child_render,
                            rowExpandable: record => record._children,
                        }}

                        // rowSelection={rowSelection}
                        // pagination={{
                        //     // defaultCurrent: defaultCurrent,
                        //     // defaultCurrent: getDefaultCurrent(),
                        //     defaultCurrent: Math.floor((updateFilter.start ? updateFilter.start : 0) / (updateFilter.limit ? updateFilter.limit : defaultLimit)) + 1,
                        //     // pageSize: datatableStore.dataFilter ? datatableStore.dataFilter.limit : defaultLimit,
                        //     // pageSize: pageSize ? pageSize : defaultLimit,
                        //     pageSize: updateFilter.limit ? updateFilter.limit : defaultLimit,
                        //     showSizeChanger: true,
                        //     total: aggregate ? aggregate.count : 0,
                        //     showTotal: total => `Tổng ${total} dữ liệu`
                        // }}
                        // onChange={onPageChange}
                        size="small"
                    // expandable={{
                    //   expandedRowRender: record => <p style={{ margin: 0 }}>{record.name}</p>,
                    //   rowExpandable: record => record.name !== 'Not Expandable',
                    // }}
                    />
                }
            </>
        </DefaultLayout>
    )
}

export default inject(
    'usersStore',
    'commonStore',
    'datatableStore'
)(observer(DataReport))
